<template>
    <div class="platform-timing-widget">
        <div class="col-12 d-flex align-items-center justify-content-end mb-2">
            <b-button variant="light" class="mr-auto" @click="onExport">
                Export
            </b-button>
            <b-form inline @submit.prevent>
                <b-spinner v-if="chartsLoading" small></b-spinner>
                <label class="mr-sm-2" for="breakdowns-type-filter">Type:</label>
                <b-form-select id="breakdowns-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="onTypeChange"></b-form-select>
            </b-form>
            <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2">
                <template v-slot:input="picker" style="min-width: 350px;">
                    <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                    <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                </template>
            </date-range-picker>
        </div>

        <p class="text-muted mb-0 mt-4 text-right" v-if="type === 'Tick'">Maximum time per component to process an on tick update</p>
        <p class="text-muted mb-0 mt-4 text-right" v-else>Maximum time per component to process an on bar update</p>
        
        <!-- <b-table v-if="type.toLowerCase() =='timebar' && !chartsLoading" :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center" style="margin:0 auto" show-empty>
            <template slot="empty">
                
            </template>
        </b-table> -->
        
        <p class="h1 mb-2 mt-4 text-center" v-if="Object.keys(chartOptions.series).length > 0">{{chartOptions.heading}}</p>
        <Chart @getSVG="getPlatformTiming" :key="chartOptions.UID" v-if="Object.keys(chartOptions.series).length > 0" :chartOptions="chartOptions" :chartID="chartIDs[0]"></Chart>

        <p class="h1 mb-2 mt-4 text-center" v-if="Object.keys(chartOptions1.series).length > 0">{{chartOptions1.heading}}</p>
        <Chart @getSVG="getPlatformTiming" :key="chartOptions1.UID" v-if="Object.keys(chartOptions1.series).length > 0" :chartOptions="chartOptions1" :chartID="chartIDs[1]" ></Chart>

        <p class="h1 mb-2 mt-4 text-center" v-if="Object.keys(chartOptions2.series).length > 0">{{chartOptions2.heading}}</p>
        <Chart @getSVG="getPlatformTiming" :key="chartOptions2.UID" v-if="Object.keys(chartOptions2.series).length > 0" :chartOptions="chartOptions2" :chartID="chartIDs[2]" ></Chart>

        <p class="h1 mb-2 mt-4 text-center" v-if="Object.keys(chartOptions3.series).length > 0">{{chartOptions3.heading}}</p>
        <Chart @getSVG="getPlatformTiming" :key="chartOptions3.UID" v-if="Object.keys(chartOptions3.series).length > 0" :chartOptions="chartOptions3" :chartID="chartIDs[3]" ></Chart>


    </div>
</template>

<script>
import { addHours, startOfDay, compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears, endOfDay } from 'date-fns';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { exportCsv } from '@/lib/Exporting';
import { map, clone, cloneDeep } from 'lodash';
import { numberFormat } from 'highcharts';
import Chart from '@/widgets/highchart';
import { numberWithCommas } from '@/lib/Helpers';

export default {
    name: 'platform-timing',
    data() {
        let date = new Date();
        let exportingOptions = {
            filename: "PlatformTiming",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Platform Speed"}
            },
            heading:"",
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        };
        return {
            chartOptions: {
                exporting: {
            filename: "PlatformTiming",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Platform Speed"}
            },
            heading:"",
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        },
                chart: {
                    zoomType: 'x'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                tooltip:{
                    formatter:function(tooltip){
                        return  `${format(new Date(this.x), "EEE do HH:mm")} <br><b> ${numberWithCommas(Math.floor(Number(this.y)))}ms</b>`
                    }
                },
                title:{text:""},
                legend: {
                    enabled: true,
                },
                credits: {
                    enabled: false
                },
                UID:null,
                series: []
            },
            chartOptions1: {
                exporting: {
            filename: "PlatformTiming",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Platform Speed"}
            },
            heading:"",
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        },
                chart: {
                    zoomType: 'x'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                tooltip:{
                    formatter:function(tooltip){
                        return  `${format(new Date(this.x), "EEE do HH:mm")} <br><b> ${numberWithCommas(Math.floor(Number(this.y)))}ms</b>`
                    }
                },
                title:{text:""},
                legend: {
                    enabled: true,
                },
                credits: {
                    enabled: false
                },
                UID:null,
                series: []
            },
            chartOptions2: {
                exporting: {
            filename: "PlatformTiming",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Platform Speed"}
            },
            heading:"",
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        },
                chart: {
                    zoomType: 'x'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                tooltip:{
                    formatter:function(tooltip){
                        return  `${format(new Date(this.x), "EEE do HH:mm")} <br><b> ${numberWithCommas(Math.floor(Number(this.y)))}ms</b>`
                    }
                },
                title:{text:""},
                legend: {
                    enabled: true,
                },
                credits: {
                    enabled: false
                },
                UID:null,
                series: []
            },
            chartOptions3: {
                exporting: {
            filename: "PlatformTiming",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Platform Speed"}
            },
            heading:"",
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        },
                chart: {
                    zoomType: 'x'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                tooltip:{
                    formatter:function(tooltip){
                        return  `${format(new Date(this.x), "EEE do HH:mm")} <br><b> ${numberWithCommas(Math.floor(Number(this.y)))}ms</b>`
                    }
                },
                title:{text:""},
                legend: {
                    enabled: true,
                },
                credits: {
                    enabled: false
                },
                UID:null,
                series: []
            },
            chartsLoading:false,
            hasSetupListeners: false,
            dateRange: {
                startDate: startOfDay(new Date(Date.now())),
                endDate: endOfDay(new Date(Date.now()))
            },
            dateRanges: {
                'This Week': [startOfWeek(date), endOfWeek(date)],
                'This Month': [startOfMonth(date), endOfMonth(date)],
                'Last Month': [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))],
                'This Quarter': [startOfQuarter(date), endOfQuarter(date)],
                'Last Quarter': [startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))],
                'This Year': [startOfYear(date), endOfYear(date)],
                'Last Year': [startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))],
                'All Time': [new Date('2015-12-01'), endOfYear(date)]
            },
            type: 'Tick',
            prevHour:null,
            component: null,
            filterOptions: {
                type: [
                    {
                        value: 'Tick',
                        text: 'Tick'
                    },
                    {
                        value: 'TimeBar',
                        text: 'TimeBar'
                    },
                    // {
                    //     value: 'TimeEvent',
                    //     text: 'TimeEvent'
                    // },
                    // {
                    //     value: 'Signal',
                    //     text: 'Signal'
                    // },
                    // {
                    //     value: 'OrderRequest',
                    //     text: 'OrderRequest'
                    // },
                    // {
                    //     value: 'Execution',
                    //     text: 'Execution'
                    // },
                    // {
                    //     value: 'ManualOrder',
                    //     text: 'ManualOrder'
                    // },
                    // {
                    //     value: 'Audit',
                    //     text: 'Audit'
                    // }
                ],
                component: [
                    {
                        value: null,
                        text: 'All'
                    },
                    {
                        value: 'QuoteGateway',
                        text: 'QuoteGateway'
                    },
                    {
                        value: 'FeedManager',
                        text: 'FeedManager'
                    },
                    {
                        value: 'SignalManager',
                        text: 'SignalManager'
                    },
                    {
                        value: 'OrderManager',
                        text: 'OrderManager'
                    },
                    {
                        value: 'MockTradeGateway',
                        text: 'MockTradeGateway'
                    },
                    {
                        value: 'LiveTradeGateway',
                        text: 'LiveTradeGateway'
                    }
                ]
            },
            tableOptions:{
                columns: [{key:"", value:"Bar Type"},{key:"", value:"Avg. Time (ms)"}],
                items: []
            },
            chartCount:0,
            components: [],
            fields: [],
            items: [],
            rowDetailFields: [
                {
                    key: 'Component',
                    label: 'Component'
                },
                {
                    key: 'Type',
                    label: 'Type'
                },
                {
                    key: 'Average',
                    label: 'Average',
                    formatter: function(value) {
                        return parseFloat(value).toFixed(2);
                    }
                },
                {
                    key: 'Max',
                    label: 'Max',
                    formatter: function(value) {
                        return parseFloat(value).toFixed(2);
                    }
                },
                {
                    key: 'Count',
                    label: 'Count',
                    formatter: function(value) {
                        return numberFormat(value, 0);
                    }
                }
            ],
        };
    },
    components: {
        DateRangePicker,
        Chart
    },
    mounted() {
        this.requests(true);
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('platformtiming', this.onPlatformTiming);
    },
    props: {
        height: {
            type: String,
            default: '400px'
        },
        account: {
            type: String,
            default: ''
        },
        chartIDs:{
            type:Array,
            default:()=>{return ["0","1","2","3"]}
        },
        dateEnd: {
            type: Date,
            default: null
        },
        dateStart: {
            type: Date,
            default: null
        },
        parsedType:{
            type:String,
            default:null
        }
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('platformtiming', this.onPlatformTiming);
                this.hasSetupListeners = true;
            }



            this.items = [];

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            let request = {
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'PlatformTiming',
                args: {
                    startDate: this.dateStart != null ? this.dateStart : this.dateRange.startDate,
                    endDate: this.dateEnd != null ? this.dateEnd : this.dateRange.endDate,
                    timeType: this.type,
                }
            };

            if(this.component !== null) {
                request.args.component = this.component;
            }
            this.chartsLoading = true;

            this.chartOptions = {
                exporting: {
            filename: "PlatformTiming",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Platform Speed"}
            },
            heading:"",
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        },
                chart: {
                    zoomType: 'x'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                tooltip:{
                    formatter:function(tooltip){
                        return  `${format(new Date(this.x), "EEE do HH:mm")} <br><b> ${numberWithCommas(Math.floor(Number(this.y)))}ms</b>`
                    }
                },
                title:{text:""},
                legend: {
                    enabled: true,
                },
                credits: {
                    enabled: false
                },
                UID:null,
                series: []
            };
            this.chartOptions1 = {
                exporting: {
            filename: "PlatformTiming",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Platform Speed"}
            },
            heading:"",
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        },
                chart: {
                    zoomType: 'x'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                tooltip:{
                    formatter:function(tooltip){
                        return  `${format(new Date(this.x), "EEE do HH:mm")} <br><b> ${numberWithCommas(Math.floor(Number(this.y)))}ms</b>`
                    }
                },
                title:{text:""},
                legend: {
                    enabled: true,
                },
                credits: {
                    enabled: false
                },
                UID:null,
                series: []
            };
            this.chartOptions2 = {
                exporting: {
            filename: "PlatformTiming",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Platform Speed"}
            },
            heading:"",
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        },
                chart: {
                    zoomType: 'x'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                tooltip:{
                    formatter:function(tooltip){
                        return  `${format(new Date(this.x), "EEE do HH:mm")} <br><b> ${numberWithCommas(Math.floor(Number(this.y)))}ms</b>`
                    }
                },
                title:{text:""},
                legend: {
                    enabled: true,
                },
                credits: {
                    enabled: false
                },
                UID:null,
                series: []
            };
            this.chartOptions3 = {
                exporting: {
            filename: "PlatformTiming",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Platform Speed"}
            },
            heading:"",
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        },
                chart: {
                    zoomType: 'x'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                tooltip:{
                    formatter:function(tooltip){
                        return  `${format(new Date(this.x), "EEE do HH:mm")} <br><b> ${numberWithCommas(Math.floor(Number(this.y)))}ms</b>`
                    }
                },
                title:{text:""},
                legend: {
                    enabled: true,
                },
                credits: {
                    enabled: false
                },
                UID:null,
                series: []
            };

            this.$ws.send(request);
        },
        onPlatformTiming(event) {
            this.response = event.response;
        
            this.createCharts(event)

            if(event.response instanceof Object && Object.keys(event.response).length <= 0){return;}

            //table data for exporting --------------------------------------------
            let components = [];
            for(let key in event.response) {
                for(let component in this.response[key]) {
                    if(components.indexOf(component) === -1) {
                        components.push(component);
                    }
                }
            }

            if(components.length === 0) {
                return;
            }

            this.components = components;

            this.fields = [
                {
                    key: 'timestamp',
                    label: 'Timestamp',
                    sortable: true,
                    formatter: function(value, key, item) {
                        return format(value, 'yyyy-MM-dd HH:mm:00');
                    }
                }
            ];

            for(let component of this.components){
                this.fields.push({
                    key: `${component}Average`,
                    label: 'Average',
                    sortable: true
                }, {
                    key: `${component}Max`,
                    label: 'Max',
                    sortable: true
                });
            }

            let items = [];
            for(let key in event.response) {
                let row = {
                    timestamp: new Date(key),
                    _showDetails: false,
                    reports: {}
                };

                for(let component of this.components) {
                    row['reports'][component] = [];

                    if(event.response[key].hasOwnProperty(component)) {
                        let average = 0;
                        let max = 0;
                        for(let report of event.response[key][component]) {
                            average += report.Average;
                            max = Math.max(max, report.Max);
                            row['reports'][component].push(report);
                        }
                        average /= event.response[key][component].length;
                        
                        row[`${component}Average`] = parseFloat(average).toFixed(2);
                        row[`${component}Max`] = parseFloat(max).toFixed(2);
                    } else {
                        row[`${component}Average`] = '-';
                        row[`${component}Max`] = '-';
                    }
                }

                items.push(row);
            }

            this.items = items;
        },
        onTypeChange(event) {
            this.requests();
        },
        onComponentChange(event) {
            this.requests();
        },
        createCharts(event){
            //format data
            let data = event.response
            if(Object.keys(data).length == undefined || Object.keys(data).length <= 0){
                 this.$emit("getPlatformTiming", false,false,false);
                return;
            }
           
            let type = event.request.args.timeType.toLowerCase()

            let chartOptions = [this.chartOptions, this.chartOptions1, this.chartOptions2, this.chartOptions3]
            
            let chartData = {};
            for(let item of Object.values(data)){
                
                let keys = Object.keys(item)
                keys.forEach((key)=>{
                    let max = item[key][0].Max
                    let count = item[key][0].Count;
                    let average = item[key][0].Average;

                    if(type != "tick"){
                        if(chartData.hasOwnProperty(key)){
                            let temp = chartData[key]
                            temp[0].data.push(average)
                            temp[1].data.push(max)
                        }else{
                            chartData[key] = [
                                {name:"Average",data:[average],color:"rgb(7, 77, 143)",type:"line"},
                                {name:"Max",data:[max],yAxis:1,color:"rgb(26, 240, 147,.5)",zIndex:-1,type:"column"}]
                        }
                    }else{
                        if(chartData.hasOwnProperty(key)){
                            let temp = chartData[key]
                            temp[0].data.push(max)
                            temp[1].data.push(count)
                        }else{
                            chartData[key] = [
                                {name:"Max",data:[max],color:"rgb(7, 77, 143)",type:"line"},
                                {name:"Count",data:[count],yAxis:1,color:"rgb(26, 240, 147,.5)",zIndex:-1,type:"column"}]
                        }
                    }

                    

                })
            }

            //xaxis
            let that = this
            let xaxis = {labels:{
                        prevHour:1,
                        blockAxis:false,
                        formatter:function(){
                            let formattedDate = format(new Date(this.value), "EEE HH")
                            let hour = formattedDate.slice(4,6)

                            if(that.prevHour != hour){
                                that.prevHour = hour
                                if(!that.blockAxis){
                                    that.blockAxis = true;
                                    return formattedDate + ":00"
                                }else{
                                    that.blockAxis = false;
                                }
                                
                            }
                            return ""
                        
                            },
                        rotation:-90}}
            let categories = Object.keys(data)
            let latestHeading = null;
            let headings = Object.keys(chartData)
            let order = ["QuoteGateway","FeedManager", "OrderManager", "SignalManager" ]

            let series = 0
            let titleBar = "Time Taken To Process Bars"
            let titleTick = "Maximum Time To Process Change On Tick"
            let yaxisBar = [{min:0,title:{text:"Time (ms)"}},{opposite:true,title:{text:"Max Time (ms)"}}]
            let yaxisTick = [{min:0,title:{text:"Maximum Time (ms)"}},{opposite:true,title:{text:"Tick count per minute (000)"}}]

            let maxes = {
                tick: {
                    SignalManager: {
                        left: 2 * 1000
                    }
                },
                bar: {
                    FeedManager: {
                        left: 1 * 1000
                    }
                }
            };

            for(let i in order){
                if(headings[i] == undefined){continue;}
                let chartIndex = order.indexOf(headings[i]);
                let chart = chartOptions[chartIndex];

                //set headings
                latestHeading = headings[i]

                chart.heading = latestHeading

                //xAxis
                chart.xAxis = {...xaxis, categories:categories}

                
                if(type == "tick"){
                    //set tick title
                    chart.exporting.chartOptions.title = this.setTitle(titleTick)

                    //set tick yaxis
                    let y;
                    if(Math.max(... chartData[latestHeading][0].data) >= 2000){
                        y = [{min:0,title:{text:"Maximum Time (s)"},labels:{formatter:function(){return this.value/1000}}},{opposite:true,title:{text:"Tick count per minute (000)"}}]
                    }else{
                        y = yaxisTick
                    }

                    chart.yAxis = y
                }else{
                    //set bar title
                    chart.exporting.chartOptions.title = this.setTitle(titleBar)

                    //tooltip
                    chart.tooltip = {formatter:function(tooltip){
                        return  `${format(new Date(this.x), "EEE do HH:mm")} <br><b> ${numberWithCommas(Number(this.y).toFixed(1))}</b>`
                    }}

                    //set bar yaxis
                    let y;
                    if(Math.max(... chartData[latestHeading][0].data) >= 2000){
                        y = [{min:0,title:{text:"Time (s)"},labels:{formatter:function(){return this.value/1000}}},{opposite:true,title:{text:"Max Time (s)"}}]
                    }else{
                        y = yaxisBar
                    }

                    chart.yAxis = y
                }


                //set series
                chart.series = chartData[latestHeading]

                if(type == "tick") {
                    if(maxes.tick.hasOwnProperty(latestHeading)) {
                        if(maxes.tick[latestHeading].hasOwnProperty("left")){
                            chart.yAxis[0].max = maxes.tick[latestHeading].left;
                        }
                        if(maxes.tick[latestHeading].hasOwnProperty("right")){
                            chart.yAxis[1].max = maxes.tick[latestHeading].right;
                        }
                    }
                } else {
                    if(maxes.bar.hasOwnProperty(latestHeading)) {
                        if(maxes.bar[latestHeading].hasOwnProperty("left")){
                            chart.yAxis[0].max = maxes.bar[latestHeading].left;
                        }
                        if(maxes.bar[latestHeading].hasOwnProperty("right")){
                            chart.yAxis[1].max = maxes.bar[latestHeading].right;
                        }
                        
                    }
                }

                //set UID
                let increment = 0
                if(type == "tick"){
                    increment = 10
                }
                chart.UID = chart.UID == null ? `${latestHeading}0` : latestHeading + (Number(chart.UID.replace(latestHeading, ""))+1);
            }

            this.chartsLoading = false;
        },
        onExport() {
            let items = map(this.items, clone);

            let headers = {
                timestamp: ' '
            };

            let headerRow = {
                timestamp: 'Timestamp'
            };

            for(let component of this.components) {
                headers[`${component}Type`] = component;
                headers[`${component}Average`] = '';
                headers[`${component}Max`] = '';
                headers[`${component}Count`] = '';

                headerRow[`${component}Type`] = 'Type';
                headerRow[`${component}Average`] = 'Average';
                headerRow[`${component}Max`] = 'Max';
                headerRow[`${component}Count`] = 'Count';
            }

            items.unshift(headerRow);

            let type = this.type;

            for(let item of items) {
                if(item.timestamp !== 'Timestamp') {
                    item.timestamp = format(item.timestamp, 'yyyy-MM-dd HH:mm:00');
                }

                if(item.hasOwnProperty('reports')) {
                    for(let component of this.components) {
                        item[`${component}Type`] = type;
                        item[`${component}Count`] = 0;

                        if(item.reports.hasOwnProperty(component)) {
                            for(let report of item.reports[component]) {
                                item[`${component}Count`] += report.Count;
                            }
                        }
                    }
                }
            }

            exportCsv(headers, items, 'PlatformTiming');
        },
        onRowClick(item) {
            item._showDetails = !item._showDetails;
        },
        getPlatformTiming(svg, chartID){
            if(this.parsedType != null){
                this.$emit("getPlatformTiming", svg , this.type, chartID);
                this.chartCount++;
                if(this.type == this.parsedType && this.chartCount == 4){
                    this.type = "TimeBar"
                    this.requests()
                    this.chartCount = 0;
                }
            }
        },
        setTitle(title){
            return {text:title,style:{fontSize:"1rem"}}
        },
        getTableHeaderTooltip(component) {
            let text = {
                'OrderManager': {
                    'Tick': ''
                }
            };

            return {
                html: true,
                content: `Testing ${component}`
            };
        }
    }
}
</script>