<template>
    <div class="optimiser-progress-widget">
        <chart @getSVG="getSVG" :chartID="chartID" :key="chartOptions.update" v-if="chartOptions.series[0].data.length > 0" :chartOptions="chartOptions"></chart> 
    </div>
</template>

<script>
import chart from '@/widgets/highchart'
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears, addDays } from 'date-fns';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'optimiser-progress',
    data() {
        let exportingOptions = {
            filename: "OptimiserProgress",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Running Total Vs Target"},
                
            },
           
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        };
        return {
            chartOptions: {
                chart:{type:"line"},
                exporting: exportingOptions,
                title: {
                    text: ''
                },
                xAxis: {
                    
                    categories:[],
                    labels: {
                        formatter: function() {
                            let formattedDate = format(new Date(this.value), "yyyy-MM-dd")
                            let day = formattedDate[formattedDate.length-1]

                            if(day == "5" || day == "0"){
                                return formattedDate
                            }

                            return ""
                        },
                        rotation:-90
                    },
                    
                },
                yAxis:{
                    title:{
                        text:"Count of Components"
                    },
                    
                },
                tooltip:{
                    formatter:function(tooltip){
                        return  `${format(new Date(this.x), "yyyy-MM-dd")} <br><b> ${this.y}</b>`
                    }
                },
                credits: {
                    enabled: false
                },
                series: [
                    
                    {
                        type:"line",
                        name:"Target",
                        data:[],
                        color:"teal",
                        dashStyle:"Dot",
                        marker: {
                            enabled: false
                        },
                        
                    },
                    {
                        type:"column",
                        name:"Added",
                        data:[],
                        color:"gray"
                    },
                    {
                        type:"line",
                        name:"Actual",
                        data:[],
                        color:"#000730",
                        marker: {
                            enabled: false
                        },
                    },
                    
                ],
                update:false,
            }
        };
    },
    components:{
        chart
    },
    props:{
        chartID:{
            type:String,
            default:"0"
        },
        ID:{
            type:Number,
            default:null
        },
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('liveweightings', this.onResponse);
    },
    methods: {
        requests() {

            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('liveweightings', this.onResponse);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'LiveWeightings',
                args: {
                }
            });
        },
        onResponse(event) {
            let actual = this.formatData(event.response.actual)
            let added = this.formatData(event.response.added)

            let rawTarget = this.createTarget(actual[0][0])

            let categories = rawTarget.categories

            let target = rawTarget.target


            this.chartOptions.series[0].data = target
            this.chartOptions.series[1].data = added
            this.chartOptions.series[2].data = actual

            this.chartOptions.xAxis.categories = categories
            this.chartOptions.update = !this.chartOptions.update
        },
        createTarget(startingDate){
            let response = {categories:[],target:[]}
            startingDate = new Date(startingDate)
            let startingValue = 20;
            let incrementalValue = 20;
            for(let i =0;i<100;i++){
                let newDate = addDays(startingDate, i);
                response.target.push([newDate, startingValue+(incrementalValue * i)])
                response.categories.push(newDate)
            }

            return response
        },
        formatData(data){
            let response = []
            for(let item of data){
                response.push([new Date(item.Item1),item.Item2])
            }
            return response;
        },
        getSVG(svg){
            if(this.ID != null){
                this.$emit("getSVG", svg , this.ID);
            }
        },
        onExport(){
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.items, clone);
            for(let item of items) {
                item.entryTime = format(item.entryTime, 'yyyy-MM-dd HH:mm:ss');
            }

            exportCsv(headers, items, 'OptimiserReturns');
        },
    }
}
</script>