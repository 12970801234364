<template>
    <div class="trade-platform-stats-widget">
        <div class="col-12 d-flex align-items-center justify-content-end mb-2">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
                <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2">
                        <template v-slot:input="picker" style="min-width: 350px;">
                            <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                            <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                        </template>
                </date-range-picker>
         </div>
        
        <div class="col-12 d-flex flex-column align-items-center mb-2">
            <p style="font-size:1.5rem">Fee Breakdown By Market</p>
            <b-table :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center" style="margin:0 auto" show-empty sort-by="market" :sort-desc="true">
            <template slot="empty">
                No results found
            </template>
        </b-table>
        </div>
        <div class="col-12 d-flex flex-column align-items-center mb-2">
            <p style="font-size:1.5rem">Fee Breakdown By Exchange</p>
            <b-table :items="exchangeTableOptions.items" :fields="exchangeTableOptions.columns" responsive class="text-center" style="margin:0 auto" show-empty sort-by="market" :sort-desc="true">
            <template slot="empty">
                No results found
            </template>
        </b-table>
        </div>
        <div class="col-12 d-flex flex-column align-items-center mb-2">
            <p style="font-size:1.5rem">YTD Breakdown By Exchange</p>
            <b-table :items="YTDexchangeTableOptions.items" :fields="YTDexchangeTableOptions.columns" responsive class="text-center" style="margin:0 auto" show-empty sort-by="market" :sort-desc="true">
            <template slot="empty">
                No results found
            </template>
        </b-table>
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue-daterange-picker-light';
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';
import { map, clone } from 'lodash';

export default {
    name: 'fee-breakdowns',
    data() {
        let columnsData= [
                    {
                        key: 'market',
                        label: 'Market',
                        sortable: true
                    },
                    {
                        key: 'volume',
                        label: 'Volume',
                        sortable: true
                    },
                    {
                        key: 'exchangeFees',
                        label: 'Exchange Fees',
                        sortable: true
                    },
                    {
                        key: 'commissions',
                        label: 'Commissions',
                        sortable: true
                    },
                    {
                        key: 'nfa',
                        label: 'NFA',
                        sortable: true
                    },
                    {
                        key: 'totalFeeImpact',
                        label: 'Total Fee impact',
                        sortable: true
                    }
                ]
        let exchangeOptions = columnsData.slice();
        exchangeOptions[0] = {
            key:"market",
            label:"Exchange",
            sortable:true
        }
        return {
            dateRange: {
                startDate: new Date(`${new Date(Date.now()).getFullYear().toString()}-01-01`),
                endDate: new Date(Date.now())
            },
            tableOptions: {columns:columnsData, items:[]},
            exchangeTableOptions:  {columns:exchangeOptions, items:[]},
            YTDexchangeTableOptions:  {columns:exchangeOptions, items:[]}
        };
    },
    components:{
        DateRangePicker
    },
    props:{
        ID:{
            type:Number,
            default:null
        },
        dateEnd: {
            type: Date,
            default: null
        },
        dateStart: {
            type: Date,
            default: null
        },
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('tradeperiodstats', this.onFeesBreakdown);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('tradeperiodstats', this.onFeesBreakdown);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            let start = format(this.dateRange.startDate, 'yyyy-MM-dd');
            let end = format(this.dateRange.endDate, 'yyyy-MM-dd')
            if(this.dateStart != null){
                start = format(this.dateStart, 'yyyy-MM-dd');
            }
            if(this.dateEnd != null){
                end = format(this.dateEnd, 'yyyy-MM-dd');
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'TradePeriodStats',
                args: {
                    StartDate: start,
                    EndDate: end,
                    YTD: true
                }
            });
        },
        onFeesBreakdown(event) {
            let data = event.response
            
            if(data[1] == undefined || data[0] == undefined){return;}

            //table 1
            data = event.response[0]
            let rows = [];
            for(let market in data){
                if(market == "totals" || market == "exchange"){continue;}
                let obj = this.createObject(data[market], market)
                rows.push(obj)
            }
            this.tableOptions.items = rows;
            //table 2
            if(data.hasOwnProperty("exchange")){
                rows = [];
                for(let exchange in data.exchange.data){
                    let obj = this.createObject(data.exchange.data[exchange], exchange)
                    rows.push(obj)
                }
                this.exchangeTableOptions.items = rows;
            }

            //table 3
            data = event.response[1]
            rows = [];
            if(data.hasOwnProperty("exchange")){
                for(let exchange in data.exchange.data){
                    let obj = this.createObject(data.exchange.data[exchange], exchange)
                    rows.push(obj)
                }
            }
            
            this.YTDexchangeTableOptions.items = rows;

            this.$emit("getRawData", [this.tableOptions.items, this.exchangeTableOptions.items, this.YTDexchangeTableOptions.items], this.ID)

        },
        formatProperty(name){
            name = name[0].toUpperCase() + name.slice(1)
            return name.replace(/([A-Z])/g, ' $1').trim()
        },
        createObject(obj, key){
            let executions = (obj.hasOwnProperty("volume") || obj.hasOwnProperty("executions")) ?  (obj.hasOwnProperty("volume") ? obj.volume : obj.executions) : 0
            let exchangeFees = obj.hasOwnProperty("exchangeFees") ? (obj.exchangeFees.hasOwnProperty("cash") ? obj.exchangeFees.cash : obj.exchangeFees) : 0
            let commission = obj.hasOwnProperty("commission") ? (obj.commission.hasOwnProperty("cash") ? obj.commission.cash : obj.commission) : 0
            let NFA = executions * 0.02;
            let fee = (exchangeFees - NFA);
            let feeImpact = (NFA / 100).toFixed(3);
            return {
                "market":key,
                "volume":executions,
                "exchangeFees":"-$"+fee.toFixed(2),
                "commissions":"-$"+commission.toFixed(2),
                "nfa": "-$"+NFA.toFixed(2),
                "totalFeeImpact": "-"+feeImpact+"%"
                
            }
        },
        onExport(){
            let headers = {};
            let emptyObj = {};

            for(let column of this.tableOptions.columns) {
                headers[column.key] = column.label;
                emptyObj[column.key] = "";
            }
            let items = [...this.tableOptions.items,emptyObj, ...this.exchangeTableOptions.items,emptyObj, ...this.YTDexchangeTableOptions.items]
            //let items = map(this.tableOptions.items,clone)
            
            exportCsv(headers, items, 'FeeBreakdown');
        }
    }
}
</script>