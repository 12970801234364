<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Report Creation">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div>
                        <b-card no-body>
                            <b-tabs card>
                              <b-tab title="Report" active>
                                <b-card-text>
                                    <ReportCreation></ReportCreation>
                                </b-card-text>
                              </b-tab>
                              <b-tab title="Daily Report" >
                                    <b-card-text>
                                        <PerformanceSummary></PerformanceSummary> 
                                    </b-card-text>
                              </b-tab>
                                </b-tabs>
                              </b-card>
                            </div>
                        </div>
                    </div>
                </div>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import ReportCreation from '@/widgets/ReportCreation';

export default {
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        ReportCreation
    }
}
</script>