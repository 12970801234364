<template>
    <div class="trade-platform-stats-widget">
        <div class="col-12 d-flex align-items-center justify-content-end mb-2">
            <b-button variant="light" class="mr-2" @click="onExport">
                Export
            </b-button>
            <date-range-picker v-if="!tableOnly" v-model="dateRange" opens="left" @update="requests" class="mr-2">
                    <template v-slot:input="picker" style="min-width: 350px;">
                        <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                        <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                    </template>
            </date-range-picker>
         </div>
        <b-table :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center" style="margin:0 auto" show-empty sort-by="Duration" :sort-desc="true">
            <template slot="empty">
                No results found
            </template>
            
        </b-table>
    </div>
</template>

<script>
import DateRangePicker from 'vue-daterange-picker-light';
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'trade-platform-stats',
    data() {
        return {
            dateRange: {
                startDate: this.getSunday(new Date(Date.now())),
                endDate: this.getFriday(new Date(Date.now()))
            },
            tableOptions: {
                columns: [
                    {
                        key: 'StatType',
                        label: 'Stat Type',
                    },
                    {
                        key: 'Cash',
                        label: 'Cash',
                    },
                    {
                        key: 'Percent',
                        label: 'Percent',
                    }
                ],
                items: []
            }
        };
    },
    props:{
        dateEnd: {
            type: Date,
            default: null
        },
        dateStart: {
            type: Date,
            default: null
        },
        tableOnly:{
            type: Boolean,
            default: false
        },
        ID:{
            type: Number,
            default:null
        }
    },
    components:{
        DateRangePicker
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('tradeperiodstats', this.onTradeperiodStats);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('tradeperiodstats', this.onTradeperiodStats);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'TradePeriodStats',
                args: {
                    StartDate: this.dateStart == null ? format(this.dateRange.startDate, 'yyyy-MM-dd') : format(this.dateStart, 'yyyy-MM-dd'),
                    EndDate: this.dateEnd == null ? format(this.dateRange.endDate, 'yyyy-MM-dd') : format(this.dateEnd, 'yyyy-MM-dd'),
                }
            });
        },
        onTradeperiodStats(event) {
            let rows = [];
            let order = ["aum","totalreturn", "grossreturn", "commission", "exchangefees", "optimiserreturns", "tolerance", "error", "numberoftrades"];
            let headers = [];
            if(event.response.hasOwnProperty('totals')){
                let totals = event.response.totals

                if(!totals.hasOwnProperty("totalReturn")){
                    totals.totalReturn = {field:"totalReturn", cash:0, percent:0}
                }
                if(!totals.hasOwnProperty("commission")){
                    totals.commission = {field:"commission", cash:0, percent:0}
                }
                if(!totals.hasOwnProperty("exchangeFees")){
                    totals.exchangeFees = {field:"exchangeFees", cash:0, percent:0}
                }
                if(!totals.hasOwnProperty("aum")){
                    totals.aum = {field:"aum", cash:0, percent:0}//added to be assets under management, Sets the value of the AUM to what the equityTracker.GetFixedOrDayStartBalance
                }  //DONT THINK THIS IS THE RIGHT WAY. NEED TO MAYBE have connection to equityTracker and pull info from there.
                let grossReturnCash = (totals.totalReturn.cash + totals.commission.cash + totals.exchangeFees.cash)
                let grossReturnPercent = (totals.totalReturn.percent + totals.commission.percent + totals.exchangeFees.percent)
                totals.grossReturn = {cash:grossReturnCash,percent:grossReturnPercent};
                let negatives = ["exchangefees", "commission", "aum"];
                for(let field in totals){
                    headers.push(this.formatProperty(field))
                    if(totals[field]["cash"] <= -1){
                        negatives.push(field.toLowerCase())
                    }
                    let obj = {
                        "StatType":this.formatProperty(field),
                        "Cash": field=="numberOfTrades" ? Math.abs(totals[field]["cash"]).toFixed(0) : "$"+Math.abs(totals[field]["cash"]).toFixed(2),
                        "Percent":field=="numberOfTrades" ? "-" : Math.abs(totals[field]["percent"]).toFixed(3) + "%"
                    }
                    
                    if(negatives.indexOf(field.toLowerCase()) != -1){
                        obj.Cash = "-" + obj.Cash
                        obj.Percent = "-" + obj.Percent
                    }
                    if(order.indexOf(field.toLowerCase()) != -1){
                        rows[order.indexOf(field.toLowerCase())] = obj;
                    }else{
                        rows.push(obj)
                    }
                    
                }
            }
            if(rows.length >= 1){
                this.tableOptions.items = rows;
            }
            
   

            this.$emit("getData", rows, this.ID);
        },
        formatProperty(name){
            name = name[0].toUpperCase() + name.slice(1)
            return name.replace(/([A-Z])/g, ' $1').trim()
        },
        getSunday(d) {
            d = new Date(d);
            let day = d.getDay();
            let diff = d.getDate() - day + (day == 0 ? -7:0);
            return new Date(d.setDate(diff));
        },
        getFriday(d){
            let sunday = this.getSunday(d);
            return sunday.setDate(sunday.getDate() + 5);
        },
        onExport(){
            let headers = {};
            let emptyObj = {};

            for(let column of this.tableOptions.columns) {
                headers[column.key] = column.label;
                emptyObj[column.key] = "";
            }

            let items = []

            for(let x in this.tableOptions.items){
                items.push(this.tableOptions.items[x])
            }
            //let items = map(this.tableOptions.items,clone)
            
            exportCsv(headers, items, 'TradePeriodStats');
        }
    }
}
</script>